$geometria: 'Geometria', sans-serif;
$roboto: 'Roboto', Helvetica, Arial, sans-serif;
$aristotelicaDisplay: 'Aristotelica Display Regular', sans-serif;
$white: #fff;
$black: #000;
$gray: #333333;
$darkgray: #131313;
$lightgray: #555555;

$pink: rgba(255, 103, 182, 1);
$red: rgba(244, 87, 30, 1);
$magenta: rgba(101, 58, 170, 1);
$blue: rgba(164, 148, 254, 1);
$green: rgba(178, 221, 58, 1);