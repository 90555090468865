$path: '../../assets/images';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

html {
  overflow-y: auto !important ;
}

body {
  margin: 0;
  max-width: 100vw;
  font-family: $roboto !important;
  color: $black;
  overflow-x: hidden;
}

html,
body {
  height: 100%;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  z-index: 1;
}

.nav-tabset:not(.disable-style) {
  justify-content: center;
  @media screen and (max-width: 960px) {
    margin-top: 70px;
  }
}
.nav-tabset:not(.disable-style) .nav-tab.active {
  border-color: #61c5cc;
}
.button {
  border: 0;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0 80px 0 10px;
  width: 590px;
  max-width: 100%;
  height: 50px;
  font-size: 22px;
  font-weight: bold;
  border-radius: 38px;
  color: White;
  background-color: $pink;
  transition: 0.2s ease;
  position: relative;
  @media (max-width: 320px) {
    font-size: 12px;
  }
  &::before {
    width: 75px;
    height: 40px;
    border-radius: 25px;
    background: white;
    order: 1;
    content: '';
    position: absolute;
    top: 5px;
    right: 5px;
  }
  &::after {
    z-index: 1;
    flex-shrink: 0;
    position: absolute;
    top: calc(50% - 10px);
    right: 30px;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    transition: 0.5s ease;
    background: center center / 100% url('#{$path}/2025/arrowPink.svg') no-repeat;
  }
  &:hover {
    background: $magenta;
    color: White;
    cursor: pointer;
    &::after {
      transform: rotate(45deg);
      background: center center / 100% url('#{$path}/2025/arrowMagenta.svg') no-repeat;
    }
  }
  &-red {
    background: $red;
    &::after {
      background: center center / 100% url('#{$path}/2025/arrowRed.svg') no-repeat;
    }
    &:hover {
      background: $blue;
      color: White;
      cursor: pointer;
      &::after {
        background: center center / 100% url('#{$path}/2025/arrowBlue.svg') no-repeat;
      }
    }
  }
  &-green {
    background: $green;
    &::after {
      background: center center / 100% url('#{$path}/2025/arrowGreen.svg') no-repeat;
    }
    &:hover {
      background: $magenta;
      color: White;
      cursor: pointer;
      &::after {
        background: center center / 100% url('#{$path}/2025/arrowMagenta.svg') no-repeat;
      }
    }
  }
  
  &-magenta {
    background: $magenta;
    &::after {
      background: center center / 100% url('#{$path}/2025/arrowMagenta.svg') no-repeat;
    }
    &:hover {
      background: $blue;
      color: White;
      cursor: pointer;
      &::after {
        background: center center / 100% url('#{$path}/2025/arrowBlue.svg') no-repeat;
      }
    }
  }
  @media (min-width: 768px) {
    font-size: 30px;
    height: 70px;
    &::before {
      height: 50px;
      top: 10px;
      right: 10px;
      border-radius: 30px;
      width: 90px;
    }
    &::after {
      right: 40px;
      width: 28px;
      height: 25px;
      background-size: 100%;
      top: calc(50% - 12px);
    }
  }
}

.title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 18px;
  color: $darkgray;
  @media (min-width: 768px) {
    font-size: 40px;
  }
}
.flexBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.designElement {
  position: absolute;
  display: block;
}
.container {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
  @media (max-width: 500px) {
    padding: 0 15px;
  }
}
.blockWithShadow {
  border-radius: 20px;
  box-shadow: 6.1px 20.1px 50px rgba(66, 112, 236, 0.15);
}
.section-title {
  text-align: center;
  text-transform: uppercase;
  color: $magenta;
  font-size: 50px;
  font-weight: bold;
  @media screen and (max-width: 1366px) {
    font-size: 45px;
  }
  @media screen and (max-width: 1120px) {
    font-size: 38px;
  }
  @media screen and (max-width: 1023px) {
    font-size: 35px;
  }
  @media screen and (max-width: 500px) {
    font-size: 24px;
  }
}

.mat-dialog-content {
  position: relative;
  overflow-x: hidden !important;
}

.program-popup {
  max-width: 60vw !important;

  .mat-dialog-container {
    padding: 0;
  }
  .mat-dialog-content {
    padding: 0;
    margin: 0;
  }

  @media screen and (max-width: 500px) {
    max-width: 80vw !important;
  }
}

.expert-popup {
  width: 80%;
  border-radius: 20px;
  overflow: hidden;
  min-width: 1000px;
  max-width: 1200px !important;

  @media screen and (max-width: 1023px) {
    width: 80%;
    min-width: 750px;
    max-width: 750px !important;
  }
  @media screen and (max-width: 767px) {
    min-width: 500px;
    max-width: 500px !important;
  }
  @media screen and (max-width: 500px) {
    width: 90%;
    min-width: 320px;
    max-width: none !important;
  }
  @media (min-width: 1200px) {
    .mat-dialog-container {
      padding: 50px 40px 30px;
    }
  }
}

.video-popup {
  .mat-dialog-container {
    padding: 0;
  }
  .mat-dialog-content {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 555px;
    @media (max-width: 1200px) {
      min-height: 460px;
    }
    @media (max-width: 960px) {
      min-height: 440px;
    }
    @media (max-width: 768px) {
      min-height: 310px;
    }
    @media (max-width: 550px) {
      min-height: 260px;
    }
  }
}

.pinkText {
  color: $pink;
}
.grayText {
  color: $lightgray;
}
