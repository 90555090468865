@import './assets/scss/app.scss';

.flex-content {
  flex: 1;
}

.b-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
}
